import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { ScaleModal } from '@telekom/scale-components-react';
import {
  Breadcrumbs,
  Container,
  Grid,
  Typography,
} from '@mui/material';

import rbacCheck from '../../../../../rbac/rbacCheck';
import DeleteForm from '../../../../../components/DeleteForm';
import OverviewTable from '../../../../../components/OverviewTable/OverviewTable';
import EditProductForm from './EditProductForm';
import ProductMembers from './ProductMembers';

import { deleteProduct, fetchProducts } from '../../../../../redux/safescarf/products/actions';
import { resetDeletionStatus } from '../../../../../redux/status/actions';
import { fetchProductTypes } from '../../../../../redux/safescarf/productTypes/actions';
import { fetchUsers as fetchSafescarfUsers } from '../../../../../redux/safescarf/users/actions';
import { fetchUserProfile } from '../../../../../redux/safescarf/userProfile/actions';
import { isEmptyObject } from '../../../../../helpers/isEmptyObject';

const ProductDetails = () => {
  const { productId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [editModalOpened, setEditModalOpened] = useState(false);
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const deletionStatus = useSelector((state) => state.status.deletionStatus);
  const userData = useSelector((state) => state.user);
  const products = useSelector((state) => state.safescarf.products);
  const productTypes = useSelector((state) => state.safescarf.productTypes);
  const safescarfUsers = useSelector((state) => state.safescarf.users);
  const safeScarfUserProfile = useSelector((state) => state.safescarf.userProfile);

  const product = products.find((p) => p.id === Number(productId));

  useEffect(() => {
    if (isEmptyObject(safeScarfUserProfile)) dispatch(fetchUserProfile());
    if (!products.length) dispatch(fetchProducts());
    if (!productTypes.length) dispatch(fetchProductTypes());
  }, [safeScarfUserProfile, products, productTypes]);

  useEffect(() => {
    const canGetSafeScarfUsers = !isEmptyObject(safeScarfUserProfile) && rbacCheck(
      userData.roles,
      '/products/safescarf/products:GET_USERS',
      { product, safescarfUser: safeScarfUserProfile },
    );

    if (canGetSafeScarfUsers && !safescarfUsers.length) {
      dispatch(fetchSafescarfUsers({ productId }));
    }
  }, [safescarfUsers, safeScarfUserProfile, userData]);

  useEffect(() => {
    if (deletionStatus === 'success') {
      navigate(-1);
    }
    return () => {
      dispatch(resetDeletionStatus());
    };
  }, [deletionStatus, dispatch, navigate]);

  const canEditProduct = rbacCheck(
    userData.roles,
    '/products/safescarf/products:PUT',
    { product, safescarfUser: safeScarfUserProfile },
  );

  const canDeleteProduct = rbacCheck(userData.roles, '/products/safescarf/products:DELETE');

  const productTypeLookup = productTypes.reduce((acc, productType) => {
    acc[productType.id] = productType.name;
    return acc;
  }, {});

  return (
    <>
      {editModalOpened && (
        <ScaleModal
          opened
          size="small"
          heading="Edit Product"
          onScale-close={() => setEditModalOpened(false)}
        >
          <EditProductForm
            product={product}
            onCancel={() => setEditModalOpened(false)}
            onSubmit={() => setEditModalOpened(false)}
          />
        </ScaleModal>
      )}
      {deleteModalOpened && (
        <ScaleModal
          opened
          size="small"
          heading="Delete Product"
          onScale-close={() => setDeleteModalOpened(false)}
        >
          <DeleteForm
            fieldName="product name"
            fieldValue={product?.name || ''}
            messages={['deleteProductMessage', 'deleteProductConfirmation']}
            deleteFunc={() => dispatch(deleteProduct(productId))}
            onClose={() => setDeleteModalOpened(false)}
          />
        </ScaleModal>
      )}
      <Grid
        className="content-with-header"
        container
        direction="column"
      >
        <Grid item alignSelf="flex-start">
          <Breadcrumbs key={product?.name} separator=">">
            <Link to="/products/safescarf">Safescarf</Link>
            <Link to="/products/safescarf/products">products</Link>
            <Typography>{product?.name}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Container maxWidth="lg">
            <Grid
              container
              direction="column"
              padding="2rem 0"
              rowGap={4}
            >
              <Grid item>
                <OverviewTable
                  header={product?.name}
                  rows={[
                    { title: 'Product type:', value: productTypeLookup[product?.prod_type] },
                    { title: 'Description:', value: product?.description },
                  ]}
                  editAction={{
                    onClick: () => setEditModalOpened(true),
                    disabled: !canEditProduct,
                    tooltip: 'editProductDisabled',
                  }}
                  deleteAction={{
                    onClick: () => setDeleteModalOpened(true),
                    disabled: !canDeleteProduct,
                    tooltip: 'safescarfProductDeleteDisabled',
                  }}
                />
              </Grid>
              <Grid item>
                <ProductMembers safescarfUsers={safescarfUsers} />
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};


export default ProductDetails;
