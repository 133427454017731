import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ScaleModal } from '@telekom/scale-components-react';
import {
  Breadcrumbs,
  Container,
  Grid,
  Typography,
} from '@mui/material';

import rbacCheck from '../../../../../rbac/rbacCheck';
import OverviewTable from '../../../../../components/OverviewTable/OverviewTable';
import EditProductTypeForm from './EditProductTypeForm';

import { fetchUsers as fetchSafescarfUsers } from '../../../../../redux/safescarf/users/actions';
import { fetchUserProfile } from '../../../../../redux/safescarf/userProfile/actions';
import { fetchProductTypes } from '../../../../../redux/safescarf/productTypes/actions';
import ProductTypeMembers from './ProductTypeMembers';
import { isEmptyObject } from '../../../../../helpers/isEmptyObject';

const ProductTypeDetails = () => {
  const { productTypeId } = useParams();
  const dispatch = useDispatch();

  const [editModalOpened, setEditModalOpened] = useState(false);

  const userData = useSelector((state) => state.user);
  const safescarfUsers = useSelector((state) => state.safescarf.users);
  const productTypes = useSelector((state) => state.safescarf.productTypes);
  const safeScarfUserProfile = useSelector((state) => state.safescarf.userProfile);

  const productType = productTypes.find((pt) => pt.id === Number(productTypeId));

  useEffect(() => {
    if (isEmptyObject(safeScarfUserProfile)) dispatch(fetchUserProfile());
    if (!productTypes.length) dispatch(fetchProductTypes());
  }, [safeScarfUserProfile, productTypes]);

  useEffect(() => {
    const canGetSafeScarfUsers = !isEmptyObject(safeScarfUserProfile) && rbacCheck(
      userData.roles,
      '/products/safescarf/product-types:GET_USERS',
      { safescarfUser: safeScarfUserProfile, productType },
    );

    if (canGetSafeScarfUsers && !safescarfUsers.length) {
      dispatch(fetchSafescarfUsers({ productTypeId }));
    }
  }, [safescarfUsers.length, safeScarfUserProfile, userData]);

  const canEditProductType = safeScarfUserProfile && rbacCheck(
    userData.roles,
    '/products/safescarf/product-types:PUT',
    { safescarfUser: safeScarfUserProfile, productType },
  );


  return (
    <>
      {editModalOpened && (
        <ScaleModal
          opened
          size="small"
          heading="Edit Product Type"
          onScale-close={() => setEditModalOpened(false)}
        >
          <EditProductTypeForm
            productType={productType}
            onCancel={() => setEditModalOpened(false)}
            onSubmit={() => setEditModalOpened(false)}
          />
        </ScaleModal>
      )}
      <Grid
        className="content-with-header"
        container
        direction="column"
      >
        <Grid item alignSelf="flex-start">
          <Breadcrumbs key={productType?.name} separator=">">
            <Link to="/products/safescarf">Safescarf</Link>
            <Link to="/products/safescarf/product-types">product types</Link>
            <Typography>{productType?.name}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Container maxWidth="lg">
            <Grid
              container
              direction="column"
              padding="2rem 0"
              rowGap={4}
            >
              <Grid item>
                <OverviewTable
                  header={productType?.name}
                  rows={[
                    {
                      title: 'Description:',
                      component: productType?.description,
                    },
                  ]}
                  editAction={{
                    onClick: () => setEditModalOpened(true),
                    disabled: !canEditProductType,
                    tooltip: 'editProductTypeDisabled',
                  }}
                />
              </Grid>
              <Grid item>
                <ProductTypeMembers safescarfUsers={safescarfUsers} />
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default ProductTypeDetails;
