import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { ScaleButton } from '@telekom/scale-components-react';
import { regexValidator } from '../../../../helpers/regexValidator';
import { FormikTextField } from '../../../../components/Formik/index';

const validationSchema = Yup.object().shape({
  osProjectName: Yup.string()
    .required('Required')
    .matches(regexValidator.noSpace, 'Spaces not allowed'),
});

const EvaluationForm = ({ disabled, onSubmit }) => (
  <Formik
    initialValues={{ osProjectName: '' }}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({ dirty, isValid }) => (
      <Form>
        <FormikTextField
          name="osProjectName"
          label="Project Name"
          disabled={disabled}
        />
        <ScaleButton
          type="submit"
          variant="primary"
          size="small"
          disabled={!dirty || !isValid}
        >
          Submit
        </ScaleButton>
      </Form>
    )}
  </Formik>
);

EvaluationForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default EvaluationForm;
