import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';
import Table from '../../../../components/Table';
import useApiCall from '../../../../hooks/useApiCall';
import Auth from '../../../../api/auth';

const productsTypeFilterMap = {
  caas: ['caasonopenstack', 'caasonbaremetal'],
  compute: ['evaluation', 'compute'],
};

const UserProducts = ({ type }) => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const getProductsCall = useApiCall(Auth.fetchV2Products);

  useEffect(() => {
    const getProducts = async () => {
      const [data] = await getProductsCall({ type: productsTypeFilterMap[type] });
      if (data) setProducts(data);
    };

    getProducts();
  }, [type]);

  const header = useMemo(() => `${type.charAt(0).toUpperCase()}${type.slice(1)} Products`, [type]);

  const fields = useMemo(() => [
    { title: 'Name', id: 'name' },
    { title: 'Description', id: 'description' },
    { title: 'Type', id: 'type' },
    { title: 'Project', id: 'project' },
    { title: 'Juicegroup', id: 'juicegroup' },
    { title: 'Approved', id: 'approved' },
  ], []);

  const rows = useMemo(() => products.map(({
    _id, name, description, marketplaceItem, project, details, approved,
  }) => ({
    key: _id,
    onRowClick: () => navigate(_id),
    data: [
      {
        field: 'name',
        value: name,
      },
      {
        field: 'description',
        value: description,
      },
      {
        field: 'type',
        value: marketplaceItem.name,
      },
      {
        field: 'project',
        value: project.name,
      },
      {
        field: 'juicegroup',
        value: details.juiceGroup,
      },
      {
        field: 'approved',
        value: approved ? 'True' : 'False',
      },
    ],
  })), [products, navigate]);

  return (
    <Container maxWidth="lg">
      <Table
        header={header}
        fields={fields}
        rows={rows}
      />
    </Container>
  );
};

UserProducts.propTypes = {
  type: PropTypes.oneOf(Object.keys(productsTypeFilterMap)).isRequired,
};

export default UserProducts;
