import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, Outlet } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { ScaleSidebarNav, ScaleSidebarNavItem } from '@telekom/scale-components-react';

import safescarfLogo from './safescarf_logo.png';

import { fetchUserProfile } from '../../../../../redux/safescarf/userProfile/actions';
import { isEmptyObject } from '../../../../../helpers/isEmptyObject';
import rbacCheck from '../../../../../rbac/rbacCheck';

const Instance = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const userData = useSelector((state) => state.user);
  const safeScarfUserProfile = useSelector((state) => state.safescarf.userProfile);
  const canSeeUsers = rbacCheck(userData.roles, '/products/safescarf/users:VIEW');
  const loading = useSelector((state) => state.status.loading);

  useEffect(() => {
    if (isEmptyObject(safeScarfUserProfile)) dispatch(fetchUserProfile());
  }, [safeScarfUserProfile]);

  // Sidebar Items
  const sidenavItems = [
    { title: 'Overview' },
    ...(canSeeUsers ? [{ title: 'Users', path: 'users' }] : []),
    { title: 'Machine Accounts', path: 'machine-accounts' },
    { title: 'Product Types', path: 'product-types' },
    { title: 'Products', path: 'products' },
    { title: 'Documentation', path: '/docs/SafeSCARF%20product' },
    { title: 'Contact Support', path: '/contact-us' },
  ];

  const getActiveSideNavItem = (path) => pathname.split('/')[3] === path;

  if (loading) {
    return null;
  }

  return !isEmptyObject(safeScarfUserProfile) ? (
    <Grid container direction="column" className="content-with-header">
      <Grid item>
        <Typography variant="h4" color="primary">
          <img
            src={safescarfLogo}
            alt=""
            style={{ width: '2rem', marginBottom: '-0.5rem' }}
          />
          &nbsp;SafeSCARF
        </Typography>
      </Grid>
      <Grid item paddingTop="2rem">
        <Grid container justifyContent="space-between">
          <Grid item xs={2}>
            <ScaleSidebarNav>
              {sidenavItems.map((item) => (
                <ScaleSidebarNavItem
                  key={item.title}
                  active={getActiveSideNavItem(item.path)}
                >
                  <Link to={item.path}>{item.title}</Link>
                </ScaleSidebarNavItem>
              ))}
            </ScaleSidebarNav>
          </Grid>
          <Grid item xs={9}>
            <Outlet />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <>
      <Typography
        variant="h2"
        color="primary"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        You are not assigned to safescarf.
      </Typography>
      <Typography variant="h6" style={{ display: 'flex', justifyContent: 'center' }}>
        Please contact the owner of the product.
      </Typography>
    </>
  );
};

export default Instance;
