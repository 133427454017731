import { useEffect, useMemo, useState } from 'react';
import useApiCall from './useApiCall';

const useTableApiCall = (
  isPaginated,
  setRows,
  endpoint,
  endpointParams,
  itemsPerPage = 10,
  searchColumn = '',
) => {
  const apiCall = useApiCall(endpoint);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState('');

  const params = useMemo(() => (
    isPaginated ? {
      pagination: true,
      page: currentPage,
      limit: itemsPerPage,
      ...(filter && { [searchColumn]: filter }),
      ...endpointParams,
    } : endpointParams
  ), [currentPage, filter]);

  const fetchData = async () => {
    const [data] = await apiCall(params);
    if (data) {
      setRows(data.docs || data);
      setTotalItems(data?.metadata?.totalItems || 0);
    } else {
      setRows([]);
      setTotalItems(0);
    }
  };

  useEffect(() => {
    if (!isPaginated) fetchData();
  }, []);

  useEffect(() => {
    if (isPaginated) fetchData();
  }, [params]);

  return {
    isPaginated,
    totalItems,
    currentPage,
    setCurrentPage,
    filter,
    setFilter,
    itemsPerPage,
    fetchData,
  };
};

export default useTableApiCall;
