import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from '../../../../../components/Table';
import Provision from '../../../../../api/provision';
import useApiCall from '../../../../../hooks/useApiCall';

const OpenstackClusters = ({ product, setActiveResources }) => {
  const [clusters, setClusters] = useState([]);
  const getCaasClusters = useApiCall(Provision.fetchCaasClustersV1);

  async function getProductResources() {
    const [data] = await getCaasClusters({ juiceGroup: product.details.juiceGroup });
    if (data) {
      setClusters(data);
      setActiveResources(data.some((cluster) => ['Pending', 'Approved', 'Provisioned'].includes(cluster.status)));
    }
  }

  useEffect(() => {
    if (product.details.juiceGroup) {
      getProductResources();
    }
  }, [product.details.juiceGroup]);

  const fields = [
    { id: 'name', title: 'Name' },
    { id: 'version', title: 'Version' },
    { id: 'cloud_id', title: 'Cloud' },
    { id: 'created', title: 'Created At' },
    { id: 'last_updated', title: 'Last Updated' },
  ];

  const rows = clusters.map((cluster) => ({
    key: `${cluster.name}-${cluster.version}-${cluster.cloud_id}`,
    data: [
      { field: 'name', value: cluster.name },
      { field: 'version', value: cluster.version },
      { field: 'cloud_id', value: cluster.cloud_id },
      { field: 'created', value: cluster.created },
      { field: 'last_updated', value: cluster.last_updated },
    ],
  }));

  return (
    <Table
      header="Clusters"
      fields={fields}
      rows={rows}
    />
  );
};

OpenstackClusters.propTypes = {
  product: PropTypes.shape({
    details: PropTypes.shape({
      juiceGroup: PropTypes.string,
    }),
  }).isRequired,
  setActiveResources: PropTypes.func.isRequired,
};

export default OpenstackClusters;
