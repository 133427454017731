import { useEffect, useMemo, useState } from 'react';
import { ScaleModal } from '@telekom/scale-components-react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coy, materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import Table from '../../components/Table';
import useApiCall from '../../hooks/useApiCall';
import Provision from '../../api/provision';
import useAppContext from '../../hooks/useAppContext';
import CopyButton from '../../components/CopyButton';

const CaasCluster = () => {
  const { theme } = useAppContext();

  const [clusters, setClusters] = useState([]);
  const [modalData, setModalData] = useState(null);

  const getCaasClusters = useApiCall(Provision.fetchCaasClustersV1);
  const getCaasClusterCheck = useApiCall(Provision.fetchCaasClusterCheck);

  useEffect(() => {
    async function getProductResources() {
      const [data] = await getCaasClusters();
      if (data) setClusters(data);
    }
    getProductResources();
  }, []);

  const handleRowClick = async (cluster) => {
    const [data] = await getCaasClusterCheck(cluster.name);
    if (data) setModalData({ cluster, logs: data });
  };

  const fields = useMemo(() => [
    { id: 'name', title: 'Name' },
    { id: 'type', title: 'Type' },
    { id: 'cloud_id', title: 'Cloud' },
    { id: 'juice_group', title: 'Juice group' },
  ], []);

  const rows = useMemo(() => clusters.map((cluster) => ({
    key: `${cluster.name}-${cluster.cloud_id}-${cluster.type}`,
    onRowClick: () => handleRowClick(cluster),
    data: [
      { field: 'name', value: cluster.name },
      { field: 'type', value: cluster.type },
      { field: 'cloud_id', value: cluster.cloud_id },
      { field: 'juice_group', value: cluster.juice_group },
    ],
  })), [clusters]);

  return (
    <>
      {modalData && (
        <ScaleModal
          opened
          size="large"
          heading={`Cluster Status Check: ${modalData.cluster?.name}`}
          onScale-close={() => setModalData(null)}
        >
          {modalData.logs && (
            <>
              <SyntaxHighlighter
                language="bash"
                style={theme === 'dark' ? materialDark : coy}
                customStyle={{
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-word',
                  fontSize: '0.75rem',
                }}
              >
                {modalData.logs}
              </SyntaxHighlighter>
              <CopyButton
                data={modalData.logs}
              />
            </>
          )}
        </ScaleModal>
      )}
      <Table
        header="Clusters"
        fields={fields}
        rows={rows}
      />
    </>
  );
};

export default CaasCluster;
