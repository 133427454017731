import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  createUser as createSafescarfUser,
  updateUser as updateSafescarfUser,
  fetchUsers as fetchSafescarfUsers,
} from '../../../../redux/safescarf/users/actions';

import {
  fetchUsers as fetchPortalUsers,
} from '../../../../redux/users/actions';


import rbacCheck from '../../../../rbac/rbacCheck';
import Table from '../../../../components/Table';

function mergeUsers(portalUsers, safescarfUsers) {
  const mergedUsers = portalUsers.map((pUser) => {
    const safescarfUser = safescarfUsers.find((ssUser) => ssUser.username === pUser.email);
    return {
      username: pUser.email,
      safescarfId: safescarfUser?.id,
      is_active: Boolean(safescarfUser?.is_active),
      isSafescarfUser: Boolean(safescarfUser),
    };
  });
  return mergedUsers;
}


const Users = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const portalUsers = useSelector((state) => state.users);
  const safescarfUsers = useSelector((state) => state.safescarf.users);
  const mergedUsers = !safescarfUsers.length ? [] : mergeUsers(portalUsers, safescarfUsers);
  const canGetAllUsers = rbacCheck(userData.roles, '/products/safescarf/users:GET');
  useEffect(() => {
    if (canGetAllUsers && !safescarfUsers.length && !portalUsers.length) {
      dispatch(fetchPortalUsers());
      dispatch(fetchSafescarfUsers());
    }
  }, [safescarfUsers, portalUsers]);

  const fields = [
    { id: 'username', title: 'Username' },
    { id: 'status', title: 'Status' },
  ];

  const rows = mergedUsers.map((user) => ({
    key: user.username,
    data: [
      { field: 'username', value: user.username },
      {
        field: 'status',
        type: 'switch',
        props: {
          disabled: !rbacCheck(userData.roles, '/products/safescarf/users:PUT', { userEmail: userData.email, rowEmail: user.username }),
          checked: user.is_active,
          label: user.is_active ? 'Active' : 'Inactive',
          'onScale-change': () => {
            if (user.isSafescarfUser) {
              dispatch(updateSafescarfUser({
                id: user.safescarfId,
                username: user.username,
                is_active: !user.is_active,
              }));
            } else {
              dispatch(createSafescarfUser({
                username: user.username,
                email: user.username,
                is_active: true,
              }));
            }
          },
        },
      },
    ],
  }));

  return (
    <Table
      header="Users"
      rows={rows}
      fields={fields}
    />
  );
};


export default Users;
